'use client';
import { Provider } from 'react-redux';
import { store } from './store';
import App from '../app';

type Props = {
   children: React.ReactNode;
};

function Providers({ children }: Props) {
   return (
      <Provider store={store}>
         <App children={children} />
      </Provider>
   );
}

export default Providers;
