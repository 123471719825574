import { createSlice } from '@reduxjs/toolkit';

const initialState = {
   status: false,
   children: null,
   className: '',
   position: 'center',
   title: '',
   mW: '',
   hideclose: false,
   outsideClick: false,
};

export const modalSlice = createSlice({
   name: 'modal',
   initialState,
   reducers: {
      setHandleStatusModal: (state, action) => {
         const {
            status,
            children,
            className = '',
            position = 'center',
            title = '',
            mW = 520,
            hideclose = false,
            outsideClick = false,
         } = action.payload;
         return {
            ...state,
            status,
            children,
            className,
            position,
            title,
            mW,
            hideclose,
            outsideClick,
         };
      },
      resetHandleStatusModal: () => initialState,
   },
});

export const { setHandleStatusModal, resetHandleStatusModal } =
   modalSlice.actions;
export default modalSlice.reducer;
