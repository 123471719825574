import { configureStore } from '@reduxjs/toolkit';
import userSlice from '@redux/reducers/userSlice';
import companySlice from '@redux/reducers/companySlice';
import modalSlice from '@redux/reducers/modalSlice';
import toastSlice from './reducers/toastSlice';
import unreadMessage from './reducers/unreadMessage';
import teamSlice from './reducers/teamSlice';
import modalTagSlice from './reducers/modalTagSlice';
import planningFilterSlice from './reducers/planningFilterSlice';
import planningSlice from './reducers/planningSlice';

export const store = configureStore({
   reducer: {
      user: userSlice,
      team: teamSlice,
      company: companySlice,
      modal: modalSlice,
      planning: planningSlice,
      modalTag: modalTagSlice,
      toast: toastSlice,
      unreadMessage: unreadMessage,
      planningFilters: planningFilterSlice,
   },
});
