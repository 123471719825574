import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
   teamData: null,
};

export const teamSlice = createSlice({
   name: 'team',
   initialState,
   reducers: {
      setTeamData: (state, action: PayloadAction<any>) => {
         state.teamData = action.payload;
      },
      clearTeamData: (state) => {
         state.teamData = null;
      },
   },
});

export const { setTeamData, clearTeamData } = teamSlice.actions;
export default teamSlice.reducer;
