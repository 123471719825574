import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
   companyData: null,
};

export const companySlice = createSlice({
   name: 'company',
   initialState,
   reducers: {
      setCompanyData: (state, action: PayloadAction<any>) => {
         state.companyData = action.payload;
      },
      clearCompanyData: (state) => {
         state.companyData = null;
      },
   },
});

export const { setCompanyData, clearCompanyData } = companySlice.actions;
export default companySlice.reducer;
