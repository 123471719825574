import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
   planningData: null,
};

export const planningSlice = createSlice({
   name: 'planning',
   initialState,
   reducers: {
      setPlanningData: (state, action: PayloadAction<any>) => {
         state.planningData = action.payload;
      },
      clearPlanningData: (state) => {
         state.planningData = null;
      },
   },
});

export const { setPlanningData, clearPlanningData } = planningSlice.actions;
export default planningSlice.reducer;
