import { createSlice } from '@reduxjs/toolkit';

const initialState = {
   status: false,
   children: null,
   className: '',
   position: 'center',
   title: '',
   mW: '',
   hideclose: false,
   color: '',
};

export const modalTagSlice = createSlice({
   name: 'modalTag',
   initialState,
   reducers: {
      setHandleStatusModalTag: (state, action) => {
         const {
            status,
            children,
            className = '',
            position = 'center',
            title = '',
            mW = 520,
            hideclose = false,
            color = '',
         } = action.payload;
         return {
            ...state,
            status,
            children,
            className,
            position,
            title,
            mW,
            hideclose,
            color,
         };
      },
      resetHandleStatusModalTag: () => initialState,
   },
});

export const { setHandleStatusModalTag, resetHandleStatusModalTag } =
   modalTagSlice.actions;
export default modalTagSlice.reducer;
