import { useDispatch, useSelector } from 'react-redux';

import styles from './Modal.module.scss';
import { useEffect } from 'react';
import { setHandleStatusModal } from '@/global/redux/reducers/modalSlice';
import { useRouter } from 'next/navigation';
import { X } from '@phosphor-icons/react';

const Modal = () => {
   const dispatch = useDispatch();
   const router = useRouter();
   const { status, children, className, title, hideclose, outsideClick } =
      useSelector((state: any) => state.modal);

   useEffect(() => {
      const handleRouteChangeComplete = (url: any) => {
         dispatch(setHandleStatusModal({ status: false }));
      };
      router?.events?.on('routeChangeComplete', handleRouteChangeComplete);
      return () => {
         router?.events?.off('routeChangeComplete', handleRouteChangeComplete);
      };
   }, [router]);

   if (!status) {
      return null;
   }

   const classNameBox = className
      ? styles.box + ' ' + styles[className]
      : styles.box;

   function handleClose() {
      dispatch(setHandleStatusModal({ status: false }));
   }

   return (
      <div className={styles.main}>
         <div className={classNameBox} onClick={(e) => e.stopPropagation(e)}>
            <div className={styles.boxHeader}>
               {title && <p className={styles.title}>{title}</p>}
               {!hideclose && (
                  <button
                     aria-label="Close Modal"
                     type={'button'}
                     onClick={handleClose}
                     className="m-button m-button--round m-button--secondary"
                  >
                     <span>
                        <X weight="bold" />
                     </span>
                  </button>
               )}
            </div>
            {children}
         </div>
      </div>
   );
};
export default Modal;
