'use client';

import Providers from '@/global/redux/Provider';

type Props = {
   children: React.ReactNode;
};

const RootComponent = ({ children }: Props) => {
   return <Providers>{children} </Providers>;
};

export default RootComponent;
