import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PlanningFilters {
   selectedProjects: { id: number }[];
}

interface PlanningState {
   filters: PlanningFilters | null;
}

const initialState: PlanningState = {
   filters: null,
};

export const planningFilterSlice = createSlice({
   name: 'planningFilters',
   initialState,
   reducers: {
      setPlanningFilters: (state, action: PayloadAction<PlanningFilters>) => {
         state.filters = action.payload;
      },
      clearPlanningFilters: (state) => {
         state.filters = null;
      },
   },
});

export const { setPlanningFilters, clearPlanningFilters } =
   planningFilterSlice.actions;
export default planningFilterSlice.reducer;
