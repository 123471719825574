import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
   nb_unread: null,
};

export const unreadMessage = createSlice({
   name: 'unreadMessages',
   initialState,
   reducers: {
      setUnreadMessages: (state, action: PayloadAction<any>) => {
         state.nb_unread = action.payload;
      },
      clearUnreadMessages: (state) => {
         state.nb_unread = null;
      },
   },
});

export const { setUnreadMessages, clearUnreadMessages } = unreadMessage.actions;
export default unreadMessage.reducer;
