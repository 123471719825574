'use client';
import styles from './Toast.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/navigation';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { setHandleStatusToast } from '@/global/redux/reducers/toastSlice';
import { set } from 'react-hook-form';
import { CheckCircle, Warning, XCircle } from '@phosphor-icons/react';
import { stat } from 'fs';

const Toast = () => {
   const dispatch = useDispatch();
   const router = useRouter();
   const { status, title, response } = useSelector((state: any) => state.toast);
   const [checkColor, setCheckColor] = useState('#FFF');

   useEffect(() => {
      if (response === 'success') {
         setCheckColor('#22C55E');
      } else if (response === 'error') {
         setCheckColor('#EF4444');
      } else setCheckColor('#F97316');
   }, [response]);

   useEffect(() => {
      const handleRouteChangeComplete = (url: any) => {
         dispatch(setHandleStatusToast({ status: false }));
      };
      router?.events?.on('routeChangeComplete', handleRouteChangeComplete);
      return () => {
         router?.events?.off('routeChangeComplete', handleRouteChangeComplete);
      };
   }, [router]);

   useEffect(() => {
      if (status) {
         const timer = setTimeout(() => {
            dispatch(setHandleStatusToast({ status: false }));
         }, 2000);
         return () => {
            clearTimeout(timer);
         };
      }
   }, [dispatch, status]);

   const renderIcon = useMemo(() => {
      if (response === 'success') {
         return <CheckCircle weight="fill" color={'#000000'} />;
      } else if (response === 'error') {
         return <XCircle weight="fill" color={'#000000'} />;
      } else {
         return <Warning weight="fill" color={'#000000'} />;
      }
   }, [status, response]);

   const renderBgColor = useCallback(() => {
      if (response === 'success') {
         return '#22C55E';
      } else if (response === 'error') {
         return '#F97316';
      } else {
         return '#EF4444';
      }
   }, [status, response]);

   return (
      <div className={styles.wrapper}>
         <div
            className={styles.toast}
            style={{ backgroundColor: renderBgColor() }}
            onClick={(e: any) => e.stopPropagation(e)}
         >
            {renderIcon}
            <div
               className={styles.content}
               style={{ backgroundColor: renderBgColor() }}
            >
               <p className={styles.title}>{title}</p>
            </div>
         </div>
      </div>
   );
};

export default Toast;
